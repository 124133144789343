import React from 'react';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

export default function WhatIsAMaster() {
    return (
        <Layout>
            <h1>What is a master? - Graham Ravey</h1>
            <div className="libraryImages">
                <StaticImage src="../../images/whatIsAMaster.jpg" alt="What is a master"/>
            </div>


            <p>During my time in Karate training I heard that a master only comes around every 50 years or so... This
                left little comfort to the fact that thousands of people are training Karate but only one can be a
                master if indeed this position is vacant during your time on earth and surely if we are all created
                equal this can not be. So we have to research the guidelines to what makes a master.</p>

            <p>Basically a master is like a diamond and must polish every facet of his martial art to a good degree
                before they can truly shine. Now good is an achievable level for us all, I did not say fantastic or
                great, just good. The hard part though is to shine in every facet to this degree, both mental and
                physical. How many times do we see Karate practitioners who shine only in their naturally gifted points
                but neglect to practice their weak points.</p>

            <p>For example, this Karateka has a great kick, punching is awesome, but his kata is fair, teaching ability
                good but flexibility poor, mental & spiritual outlook needs a good polish, etc, etc.</p>

            <p>So I believe it is within everyone's reach with much hard work over many years to achieve the standard of
                good in every facet of Karate, thus becoming a master. Sad to say, only a few will accomplish this
                because of the hard work involved and the humbleness of admitting to our weak points, then doing
                something about them.</p>

            <p>Many people follow a leader who has only polished their physical facets to a degree of greatness but
                neglected to shine the mental and communication facets. These leaders are then given the title of master
                for their physical prowess only.</p>

            <p><strong>Sensei Graham Ravey</strong></p>
        </Layout>);
}

